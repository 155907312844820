import * as React from 'react';
import { Callout, NonIdealState, Spinner } from '@blueprintjs/core';
import { Resource, HTTPError } from '@optics/hal-client';
import titleCase from 'title-case';

type RenderOptions = {
  items: Resource[];
  refresh: () => void;
}

type AlwaysOptions = {
  content: React.ReactNode;
  refresh: () => void;
}

type Props = {
  resource: Resource;
  rel: string;
  name?: string;
  params?: {};
  empty?: (options: RenderOptions) => React.ReactNode;
  always?: (options: AlwaysOptions) => React.ReactNode;
  children: (options: RenderOptions) => React.ReactNode;
}

type State = {
  loading: boolean;
  total: number;
  items: Resource[];
  pageCount: number;
  currentPage: number;
  error?: any;
}

const defaultAlways = (options: AlwaysOptions) => options.content;

export class ResourceCollection extends React.Component<Props, State> {
  static defaultProps = {
    name: 'collection',
    params: {}
  }

  state: State = {
    loading: true,
    items: [],
    total: 0,
    pageCount: 0,
    currentPage: 1
  }

  render() {
    const { always = defaultAlways, children, empty, rel } = this.props;
    const { error, items, loading } = this.state;

    if (error) {
      return always({
        content: (
          <Callout intent='danger' title='Unable to display collection'>
            <pre>{error.message}</pre>
          </Callout>
        ),

        refresh: this._refresh
      });
    }

    if (loading === true) {
      return always({
        content: (
          <NonIdealState
            icon={<Spinner size={77} />}
            title={`Loading ${titleCase(rel)}`}
          />
        ),

        refresh: this._refresh
      });
    }

    const options = {
      refresh: this._refresh,
      items
    };

    if (items.length === 0) {
      return always({
        content: empty ? empty(options) : null,
        refresh: this._refresh
      });
    }

    return always({
      content: children(options),
      refresh: this._refresh
    });
  }

  componentDidMount() {
    this._fetch();
  }

  componentWillReceiveProps(nextProps: Props, nextState: State) {
    this._fetch(nextProps, nextState);
  }

  protected _refresh = () => this._fetch();

  protected _fetch = async ({ resource, rel, name, params } = this.props, { currentPage } = this.state) => {
    if (!resource.linkNamed(rel, name!)) {
      return;
    }

    this.setState({ loading: true });

    try {
      const result = await resource.linkNamed(rel, name!).fetch({
        ...params,
        page: currentPage
      });

      const items = result.hasEmbedded(rel) ?
        result.embedded(rel) as Resource[] : [];

      this.setState({
        total: result.properties.count,
        error: undefined,
        items
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  }
}
