import * as React from 'react';
import { ButtonGroup, H1, H2, NonIdealState } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';
import { UIRouterConsumer } from '@uirouter/react';
import { Row, Col } from 'react-flexbox-grid';

import { ResourceCollection } from '../components/generic/ResourceCollection';
import { ResourceItem } from '../components/generic/ResourceItem';
import { Button as DeleteButton } from '../components/generic/ResourceDeleteDialog/Button';

import { EditButton } from '../components/cohorts/EditButton';

import { CohortRulesList } from './CohortRulesList';
import { CohortFlagsList } from './CohortFlagsList';
import { callAndToast } from '../toaster';

type Props = {
  api: Resource;
  $stateParams: {
    id: string
  }
}

export const CohortView: React.SFC<Props> = ({ api, $stateParams: { id } }) => (
  <UIRouterConsumer>
    {router => {
      const goBack = () => router.stateService.go('cohorts')

      return (
        <ResourceItem
          resource={api}
          rel='cohorts'
          params={{ id }}
        >
          {({ item: cohort, refresh }) => (
            <>
              <Row
                middle='md'
                className='main-content-heading main-content-heading-underline'
              >
                <Col md={6}>
                  <H1>{cohort.properties.name}</H1>
                  <p>{cohort.properties.description || <span>&nbsp;</span>}</p>
                </Col>
                <Col md={6} className='heading-buttons'>
                  <ButtonGroup>
                    <EditButton
                      resource={cohort}
                      onUpdate={refresh}
                    />
                    <DeleteButton
                      resource={cohort}
                      onDelete={callAndToast(goBack, {
                        message: 'Successfully Deleted Cohort',
                        intent: 'success'
                      })}
                      message={(
                        <>
                          <p>
                            Are you sure you want to delete Cohort <b>'{cohort.properties.name}'</b>?
                </p>
                          <p>
                            This will delete any Membership Rules and Flags associated this cohort, and is not reversible.
                </p>
                        </>
                      )}
                    />
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <CohortRulesList {...{ cohort }} />
                </Col>
                <Col md={6}>
                  <CohortFlagsList {...{ cohort }} />
                </Col>
              </Row>
            </>
          )}
        </ResourceItem>
      );
    }}
  </UIRouterConsumer>
)
