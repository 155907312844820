import * as React from 'react';
import { Button, Classes, Icon, Menu, MenuItem, Navbar, Popover } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';
import { UISref, UISrefActive, UIView } from '@uirouter/react';

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import 'react-jsonschema-form-blueprint-widgets/dist/css/index.css';

const NAVBAR_LINKS = [{
  label: 'Cohorts',
  state: 'cohorts'
}, {
  label: 'Explain/Trace',
  state: 'explain'
}];

type Props = {
  identity: Resource
}

const fullName = (identity: Resource) =>
  `${identity.properties.person.firstName} ${identity.properties.person.lastName} (${identity.properties.person.email})`

export const Main: React.SFC<Props> = ({ identity }) => (
  <>
    <Navbar className={Classes.DARK}>
      <Navbar.Group>
        <Navbar.Heading>
          <Icon icon='flag' />
          Vex
        </Navbar.Heading>
        <Navbar.Divider />
        {NAVBAR_LINKS.map(({ label, state }) => (
          <UISrefActive to={state} key={state} class={Classes.ACTIVE}>
            <UISref to={state}>
              <a role="button" className={`${Classes.BUTTON} ${Classes.MINIMAL}`}>{label}</a>
            </UISref>
          </UISrefActive>
        ))}
      </Navbar.Group>
      <Navbar.Group align='right'>
        <Popover
          content={(
            <Menu>
              <UISref to='logout'>
                <MenuItem text='Log out' />
              </UISref>
            </Menu>
          )}>
          <Button
            text={fullName(identity)}
            icon='user'
            minimal
          />
        </Popover>
      </Navbar.Group>
    </Navbar>
    <div className="main-content">
      <UIView />
    </div>
  </>
);
