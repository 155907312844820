import * as React from 'react';
import { Icon, Tooltip, IconName, Intent } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';

type Props = {
  cohort: Resource;
}

export const StatusIcon: React.SFC<Props> = ({ cohort }) => {
  const rules = parseInt(cohort.meta.cohort_membership_rules_count, 10);
  const flags = parseInt(cohort.meta.cohort_flags_count, 10);

  let tooltip: string = 'This Cohort is fully configured';
  let icon: IconName = 'tick';
  let intent: Intent = 'success';

  if (rules === 0) {
    tooltip = 'This Cohort does not have any Membership Rules defined';
    icon = 'warning-sign';
    intent = 'warning';
  } else if (flags === 0) {
    tooltip = 'This Cohort does not have any Flags defined';
    icon = 'warning-sign';
    intent = 'warning';
  }

  return (
    <Tooltip content={tooltip}>
      <Icon
        {...{ icon, intent }}
        iconSize={24}
      />
    </Tooltip>
  );
}
