import * as React from 'react';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';

import { removeOneOf } from '../../utils';
import { callAndToast } from '../../toaster';
import { cohortFlags as uiSchema } from '../../uiSchema';
import { ResourceFormDialog } from '../generic/ResourceFormDialog';
import { ResourceDeleteDialog } from '../generic/ResourceDeleteDialog';

type Props = {
  flag: Resource;
  onChange: (flag: Resource) => void;
}

export class EditOrDeleteButton extends React.Component<Props> {
  protected _updateDialog = React.createRef<ResourceFormDialog>();
  protected _deleteDialog = React.createRef<ResourceDeleteDialog>();

  render() {
    const { flag, onChange } = this.props;

    return (
      <div className='edit-or-delete-button'>
        <ResourceFormDialog
          ref={this._updateDialog}
          form={{
            resource: flag,
            uiSchema,
            rel: 'self',
            name: 'update',
            mutateSchema: removeOneOf('cohort_id'),
            onSubmit: callAndToast(onChange, {
              message: 'Successfully Updated Flag',
              intent: 'success'
            }),
          }}
          dialog={{ icon: 'edit', title: 'Modify Flag' }}
        />
        <ResourceDeleteDialog
          ref={this._deleteDialog}
          resource={flag}
          message={<p>Are you sure you want to delete this Flag?</p>}
          onDelete={callAndToast(onChange, {
            message: 'Successfully Deleted Flag',
            intent: 'success'
          })}
        />
        <Popover
          content={(
            <Menu>
              <MenuItem
                icon='edit'
                text='Edit'
                onClick={this._onUpdate}
              />
              <MenuItem
                icon='trash'
                text='Delete'
                onClick={this._onDelete}
              />
            </Menu>
          )}
          position='bottom-right'
        >
          <Button minimal icon='cog' />
        </Popover>
      </div>
    )
  }

  protected _onUpdate = () =>
    this._updateDialog.current && this._updateDialog.current.open();

  protected _onDelete = () =>
    this._deleteDialog.current && this._deleteDialog.current.open();
}
