import * as React from 'react';
import { Callout, Code } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';

type Props = {
  flag: Resource
};

export const Summary: React.SFC<Props> = ({ flag }) => (
  <Callout icon='tag'>
    <Code>{flag.properties.name}</Code>
  </Callout>
);
