import * as React from 'react';
import { Resource } from '@optics/hal-client';

import { callAndToast } from '../../toaster';
import { removeOneOf } from '../../utils';
import { Button } from '../generic/ResourceFormDialog/Button';
import { cohortFlags as uiSchema } from '../../uiSchema';

type Props = {
  resource: Resource;
  onCreate: (resource: Resource) => void;
}

export const CreateButton: React.SFC<Props> = ({ resource, onCreate }) => (
  <Button
    form={{
      resource,
      uiSchema,
      rel: 'cohort_flags',
      name: 'create',
      onSubmit: callAndToast(onCreate, {
        message: 'Successfully added Flag to Cohort',
        intent: 'success'
      }),
      mutateSchema: removeOneOf('cohort_id'),
    }}
    button={{ icon: 'add', text: 'Create' }}
    dialog={{ icon: 'add', title: 'Create new Flag' }}
  />
)
