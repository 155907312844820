import * as React from 'react';
import { Button, MenuItem } from '@blueprintjs/core';
import { Suggest, ISuggestProps, ItemRenderer } from '@blueprintjs/select';
import { Resource, Link } from '@optics/hal-client';
import { path, pluck } from 'ramda';
import { WidgetProps } from 'react-jsonschema-form';
import { throttle } from 'throttle-debounce';

type Item = {
  name: string;
  value: string;
}

type Props = WidgetProps & {
  schema: WidgetProps['schema'] & {
    'x-vex-suggestions': {
      source: string;
    }
  };

  formContext: {
    resource: Resource
  };

  options: {
    rel: string;
    name: string;
    inputValueRenderer: (resource: Resource) => string;
  }
}

type State = {
  loading: boolean;
  query: string;
  items: Item[];
  selectedItem?: Item;
}

const THROTTLE_MS = 500;

const suggestionSource: (props: Props) => string = path(['schema', 'x-vex-suggestions', 'source']) as any;

export class ResourcePicker extends React.Component<Props, State> {
  state: State = {
    loading: false,
    query: '',
    items: [],
    selectedItem: undefined
  }

  protected _sourceLink = new Link(
    { href: suggestionSource(this.props) },
    this.props.formContext.resource.config
  );

  componentWillReceiveProps(nextProps: Props) {
    const nextSource = suggestionSource(nextProps);

    if (nextSource !== suggestionSource(this.props)) {
      this._sourceLink = new Link(
        { href: nextSource },
        this.props.formContext.resource.config
      )

      this.setState({
        query: '',
        loading: false,
        items: [],
        selectedItem: undefined
      })
    }

    if (nextProps.value && nextProps.value !== this.props.value) {
      this._fetchSelected(nextProps.value);
    }
  }

  render() {
    const { inputValueRenderer } = this.props.options;
    const { selectedItem, items, query } = this.state;

    return (
      <Suggest
        {...{ selectedItem, items, query }}
        onQueryChange={this._queryChange}
        onItemSelect={this._itemSelect}
        inputProps={this._inputProps()}
        noResults={this._renderEmptyList()}
        inputValueRenderer={this._renderValue}
        itemRenderer={this._renderItem}
      />
    );
  }

  protected _fetchItems = throttle(THROTTLE_MS, async (query: string) => {
    try {
      const result = await this._sourceLink.fetch({ query });

      this.setState({
        items: pluck('properties', result.embedded('suggestions') as Resource[]) as Item[]
      });
    } finally {
      this.setState({ loading: false });
    }
  });

  protected _fetchSelected = async (value: string) => {
    this.setState({ loading: true });

    try {
      const result = await this._sourceLink.fetch({ value });

      this.setState({
        selectedItem: result.properties as Item
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  protected _queryChange = (query: string) => {
    /**
     * Due to a bug in the `<Suggest/>` component, the `onQueryChange` handler
     * is fired twice, so use a callback to ensure the state change and call to
     * `_fetchItems` is only called once.
     */
    this.setState((prevState: any) => {
      if (query === prevState.query) {
        return prevState;
      }

      this._fetchItems(query);

      return {
        loading: true,
        query
      };
    });
  }

  protected _itemSelect = (item: Item) => {
    const { onChange } = this.props;
    onChange(item.value);
  }

  protected _inputProps = (): ISuggestProps<any>['inputProps'] => ({
    leftIcon: 'search',

    rightElement: (
      <Button
        minimal
        icon='arrow-right'
        loading={this.state.loading}
      />
    )
  });

  protected _renderEmptyList = () => {
    const { query } = this.state;

    const text = query ?
      'Could not find any resources matching the query specified.' :
      'Begin typing a search query to display matching resources';

    return (
      <MenuItem
        disabled={true}
        {...{ text }}
      />
    )
  }

  protected _renderValue = (item: Item) => item.name;

  protected _renderItem: ItemRenderer<Item> = (item, { handleClick, modifiers }) => (
    <MenuItem
      text={item.name}
      key={item.value}
      onClick={handleClick}
      active={modifiers.active}
    />
  )
}
