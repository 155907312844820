import * as React from 'react';
import { render } from 'react-dom';

import './index.scss';

declare var module: any;
declare var require: any;

const root = document.getElementById('root');

const init = () => {
  const { App } = require('./App');
  render(<App />, root);
}

if (module.hot) {
  module.hot.accept('./App', init);
}

init();
