import * as React from 'react';
import { Resource } from '@optics/hal-client';
import { UiSchema } from 'react-jsonschema-form';

import { callAndToast } from '../../toaster';
import { Button } from '../generic/ResourceFormDialog/Button';

import { cohorts as uiSchema } from '../../uiSchema';

type Props = {
  resource: Resource;
  onCreate: (resource: Resource) => void;
}

export const CreateButton: React.SFC<Props> = ({ resource, onCreate }) => (
  <Button
    form={{
      resource, uiSchema, rel: 'cohorts', name: 'create', onSubmit: callAndToast(onCreate, {
        message: 'Successfully Created Cohort',
        intent: 'success'
      })
    }}
    button={{ icon: 'add', text: 'Create', intent: 'success' }}
    dialog={{ icon: 'add', title: 'Create new Cohort' }}
  />
)
