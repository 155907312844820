import * as React from 'react';
import { Button, Classes, Dialog, IDialogProps } from '@blueprintjs/core';
import Form from 'react-jsonschema-form';

import { ResourceForm, Props as FormProps } from './ResourceForm';
import { Resource } from '@optics/hal-client';

export type Props = {
  form: FormProps;
  dialog: Partial<IDialogProps>;
}

type State = {
  open: boolean;
}

export class ResourceFormDialog extends React.Component<Props, State> {
  protected _formRef = React.createRef<any>();

  state: State = {
    open: false
  };

  render() {
    const { form, dialog } = this.props;
    const { open } = this.state;

    return (
      <Dialog
        {...dialog}
        isOpen={open}
        onClose={this.close}
      >
        <div className={Classes.DIALOG_BODY}>
          <ResourceForm
            {...form}
            ref={this._formRef}
            onSubmit={this._onSubmit}
          >
            <React.Fragment />
          </ResourceForm>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.close}
              text='Cancel'
            />
            <Button
              intent='primary'
              text='Submit'
              type='submit'
              form='theForm'
              onClick={this._onClick}
            />
          </div>
        </div>
      </Dialog >
    );
  }

  public open = () =>
    this.setState({ open: true });

  public close = () =>
    this.setState({ open: false });

  protected _onClick = () => {
    const form = this._formRef.current!;
    form.submit();
  }

  protected _onSubmit = (resource: Resource) => {
    const { onSubmit } = this.props.form;

    if (onSubmit) {
      onSubmit(resource);
    }

    this.close();
  }
}
