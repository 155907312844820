import { Link, Resource } from '@optics/hal-client';
import { ReactStateDeclaration, Transition } from '@uirouter/react';

import { CohortsList } from './containers/CohortsList';
import { CohortView } from './containers/CohortView';
import { Explain } from './containers/Explain';
import { Login } from './containers/Login';
import { Main } from './containers/Main';
import { Root } from './containers/Root';

const loadRootResource = async (): Promise<Resource> => {
  const token = localStorage.getItem('token');

  const rootLink = new Link({
    href: '/api'
  }, token ? {
    requestHeaders: {
      Authorization: `Bearer ${token}`
    } as any
  } : undefined);

  try {
    return await rootLink.fetch();
  } catch (e) {
    if (token && e.status === 401) {
      localStorage.removeItem('token');
      return loadRootResource();
    }

    throw e;
  }
}

const getInjectedIdentity = (transition: Transition) =>
  transition.injector('root').get('identity');

export const states: ReactStateDeclaration[] = [{
  name: 'root',
  url: '/',
  abstract: true,
  component: Root,
  resolve: {
    api: loadRootResource,

    identity: [
      'api',
      (api: Resource) => {
        if (!api.hasLinkNamed('users', 'identity')) {
          return false;
        }

        return api.linkNamed('users', 'identity').fetch();
      }
    ]
  }
}, {
  name: 'login',
  parent: 'root',
  url: 'login',
  component: Login,
  onEnter: transition => {
    const identity = getInjectedIdentity(transition);

    if (identity !== false) {
      console.info('Already logged in, redirecting to state `cohorts`.');
      return transition.router.stateService.target('cohorts');
    }

    console.info('Not logged in, continuing to state `login`.');
  }
}, {
  name: 'logout',
  parent: 'root',
  url: 'logout',
  onEnter: transition => {
    console.info('Destroying token and redirecting to state `login`.');
    localStorage.removeItem('token');

    return transition.router.stateService.target('login', undefined, {
      reload: true
    });
  }
}, {
  name: 'main',
  parent: 'root',
  url: '',
  component: Main,
  redirectTo: 'cohorts',
  onEnter: transition => {
    const identity = getInjectedIdentity(transition);

    if (identity === false) {
      console.info('Not logged in, redirecting to state `login`.');
      return transition.router.stateService.target('login');
    }

    console.info('Logged in, continuing to state `main`.');
  }
}, {
  name: 'cohorts',
  parent: 'main',
  url: 'cohorts',
  component: CohortsList
}, {
  name: 'cohorts.view',
  url: '/:id',
  views: {
    '@main': {
      component: CohortView
    }
  }
}, {
  name: 'explain',
  parent: 'main',
  url: 'explain',
  component: Explain
}];
