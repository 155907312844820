import * as React from 'react';
import { Callout, NonIdealState, Spinner } from '@blueprintjs/core';
import { Resource, HTTPError } from '@optics/hal-client';
import titleCase from 'title-case';

type RenderOptions = {
  item: Resource;
  refresh: () => void;
}

type Props = {
  resource: Resource;
  rel: string;
  name?: string;
  params?: {};
  children: (options: RenderOptions) => React.ReactNode;
}

type State = {
  loading: boolean;
  item: Resource;
  error?: any;
}

export class ResourceItem extends React.Component<Props, State> {
  static defaultProps = {
    name: 'item',
    params: {}
  }

  state: State = {
    loading: true,
    item: undefined as any
  }

  render() {
    const { children, rel } = this.props;
    const { error, item, loading } = this.state;

    if (error) {
      return (
        <Callout intent='danger' title='Unable to display collection'>
          <pre>{error.message}</pre>
        </Callout>
      );
    }

    if (loading === true) {
      return (
        <NonIdealState
          icon={<Spinner size={77} />}
          title={`Loading ${titleCase(rel)}`}
        />
      );
    }

    const options = {
      refresh: this._refresh,
      item
    };

    if (!item) {
      return null;
    }

    return children(options);
  }

  componentDidMount() {
    this._fetch();
  }

  componentWillReceiveProps(nextProps: Props) {
    this._fetch(nextProps);
  }

  protected _refresh = () => this._fetch();

  protected _fetch = async ({ resource, rel, name, params } = this.props) => {
    if (!resource.linkNamed(rel, name!)) {
      return;
    }

    this.setState({ loading: true });

    try {
      const item = await resource.linkNamed(rel, name!).fetch(params);

      this.setState({
        error: undefined,
        item
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  }
}
