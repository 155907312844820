import * as React from 'react';
import { Callout, Classes, Code, IconName, Tooltip } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';

type Props = {
  membership: Resource
}

type DescriptionOptions = {
  rule_name: string;
  parameters: {
    [key: string]: any
  };
  cached_description: {
    [key: string]: any
  };
}

type RuleFormat = {
  icon: IconName;
  description: (options: DescriptionOptions) => React.ReactNode;
}

const RULE_FORMATS: { [key: string]: RuleFormat } = {
  anonymous: {
    icon: 'help',
    description: () => 'When User is unauthenticated'
  },
  everyone: {
    icon: 'help',
    description: () => 'When a User is authenticated or unauthenticated'
  },
  everyoneLoggedIn: {
    icon: 'help',
    description: () => 'When a User is authenticated'
  },
  user: {
    icon: 'user',
    description: ({ parameters: { extId }, cached_description: { firstName, lastName, email } }) =>
      <>
        When User is&nbsp;
        <Tooltip
          className={Classes.TOOLTIP_INDICATOR}
          content={<>Person ID: <strong>{extId}</strong></>}
        >
          <strong>{firstName} {lastName} ({email})</strong>
        </Tooltip>.
      </>
  },
  firm: {
    icon: 'people',
    description: ({ parameters: { extId }, cached_description: { name } }) =>
      <>
        When User is a associated to Firm&nbsp;
        <Tooltip
          className={Classes.TOOLTIP_INDICATOR}
          content={<>Firm ID: <strong>{extId}</strong></>}
        >
          <strong>{name}</strong>
        </Tooltip>.
      </>
  },
  enterprise: {
    icon: 'office',
    description: ({ parameters: { slug } }) =>
      <>
        When User is a associated to Enterprise&nbsp;
        <Tooltip
          className={Classes.TOOLTIP_INDICATOR}
          content={<>Enterprise Slug: <strong>{slug}</strong></>}
        >
          <strong>{slug}</strong>
        </Tooltip>.
      </>
  }
}

const DEFAULT_RULE_FORMAT: RuleFormat = {
  icon: 'help',
  description: ({ rule_name, cached_description, parameters }) =>
    <>
      <p>Unknown rule <Code>{rule_name}</Code></p>
      <p>Parameters: <Code>{JSON.stringify(parameters)}</Code></p>
      <p>Cached Description: <Code>{JSON.stringify(cached_description)}</Code></p>
    </>
}

export const Summary: React.SFC<Props> = ({ membership }) => {
  const { icon, description } = RULE_FORMATS[membership.properties.rule_name] || DEFAULT_RULE_FORMAT;

  return (
    <Callout {...{ icon }} className='cohort-membership-rule-summary'>
      {description(membership.properties as any)}
    </Callout>
  );
}
