import * as React from 'react';
import { Card, Code, H1, H3, Pre, Tab, Tabs } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';
import { Row, Col } from 'react-flexbox-grid';
import sqlFormatter from 'sql-formatter';

import { ResourceForm } from '../components/generic/ResourceForm';
import { ResourcePicker } from '../components/generic/ResourcePicker';
import { Summary as RuleSummary } from '../components/cohort_membership_rules/Summary';

type Props = {
  api: Resource;
}

type State = {
  result?: Resource;
}

type Match = {
  name: string;
  flags: string[] | null;
  rules: any[];
}

export class Explain extends React.Component<Props, State> {
  state: State = {}

  render() {
    const { api } = this.props;
    const { result } = this.state;

    return (
      <>
        <H1>Explain/Trace Wizard</H1>
        <ResourceForm
          resource={api}
          rel='explain'
          onSubmit={this._onSubmit}
          uiSchema={{
            extId: {
              'ui:widget': ResourcePicker
            }
          }}
        />
        <Tabs>
          <Tab
            id='flags'
            title='Active Flags'
            disabled={!result}
            panel={result ? this._renderFlags(result.properties.flags) : this._renderEmpty()}
          />
          <Tab
            id='rules'
            title='Matches'
            disabled={!result}
            panel={result ? this._renderMatches(result.properties.matches) : this._renderEmpty()}
          />
          <Tab
            id='query'
            title='SQL Query'
            disabled={!result}
            panel={result ? this._renderQuery(result.properties.query) : this._renderEmpty()}
          />
        </Tabs>
      </>
    );
  }

  protected _renderFlags = (flags: string[]) =>
    flags.length ?
      <>
        <p>The following Flags are active for this user:</p>
        {...flags.map(flag => (
          <p>
            <Code key={flag}>{flag}</Code>
          </p>
        ))}
      </> :
      <p>This user does not have any active flags.</p>

  protected _renderMatches = (matches: Match[]) =>
    matches.length ?
      <>
        <p>This User was matched to the following Cohorts:</p>
        <Card className='card-list'>
          {...matches.map((match, index) => (
            <div
              className='card-list-item'
              key={index}
            >
              <Row>
                <Col md={12}>
                  <H3>{match.name}</H3>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p>Active Flags:</p>
                  {
                    match.flags ? match.flags.map(flag => (
                      <p><Code key={flag}>{flag}</Code></p>
                    )) :
                      <p>This Cohort does not specify any Flags.</p>
                  }
                </Col>
                <Col md={6}>
                  <p>Matched Rules:</p>
                  {...match.rules.map((rule, index) => (
                    <RuleSummary membership={new Resource(rule)} />
                  ))}
                </Col>
              </Row>
            </div>
          ))}
        </Card>
      </> :
      <p>This user does not match any Cohorts.</p>

  protected _renderQuery = (query: string) =>
    <>
      <p>Results were determined using the following SQL query:</p>
      <Pre><Code>{sqlFormatter.format(query)}</Code></Pre>
    </>


  protected _renderEmpty = () =>
    <p>Please use the form above to load a trace of applicable Flags.</p>

  protected _onSubmit = (result: Resource) =>
    this.setState({ result });
}
