import * as React from 'react';
import { Button as BaseButton, IButtonProps, IDialogProps } from '@blueprintjs/core';
import { Props as FormProps, ResourceFormDialog } from '../ResourceFormDialog';

type Props = FormProps & {
  button: IButtonProps,
}

export class Button extends React.Component<Props> {
  protected _form?: ResourceFormDialog;

  render() {
    const { form, button, dialog } = this.props;

    return (
      <>
        <BaseButton
          {...button}
          onClick={this._onClick}
        />
        <ResourceFormDialog
          {...{ form, dialog }}
          ref={this._setFormRef}
        />
      </>
    );
  }

  protected _onClick = () => {
    if (this._form) {
      this._form.open();
    }
  }

  protected _setFormRef = (form: ResourceFormDialog | null) => {
    if (form) {
      this._form = form;
    }
  }
}
