import * as React from 'react';
import { Alert, Button as BaseButton, IAlertProps, IButtonProps } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';

import { Props as DialogProps, ResourceDeleteDialog } from '../ResourceDeleteDialog';

type Props = {
  resource: Resource;
  onDelete: () => void;
  message: React.ReactNode;
  button?: Partial<IButtonProps>;
}

export class Button extends React.Component<Props> {
  protected _dialog = React.createRef<ResourceDeleteDialog>();

  render() {
    const { resource, message, button, onDelete } = this.props;

    return (
      <>
        <ResourceDeleteDialog
          {...{ resource, message, onDelete }}
          ref={this._dialog}
        />
        <BaseButton
          onClick={this._onClick}
          text='Delete'
          icon='trash'
          {...button}
        />
      </>
    );
  }

  protected _onClick = () =>
    this._dialog.current && this._dialog.current.open();
}
