import * as React from 'react';
import { Resource, HTTPError } from '@optics/hal-client';
import { Schema } from '@optics/hal-client/dist/Form';
import { Callout, Code } from '@blueprintjs/core';
import { IChangeEvent, ISubmitEvent, UiSchema } from 'react-jsonschema-form';
import { Form } from 'react-jsonschema-form-blueprint-widgets';

import { Error } from './ResourceForm/Error';

export type Props = {
  resource: Resource;
  rel: string;
  name?: string;
  onSubmit?: (resource: Resource) => void;
  uiSchema?: UiSchema;
  forwardedRef?: React.Ref<any>;
  mutateSchema?: (schema: Schema) => Schema;
}

type State = {
  submitting: boolean;
  error?: HTTPError;
  formData: any;
}

class _ResourceForm extends React.Component<Props, State> {
  state: State = {
    submitting: false,
    formData: {}
  }

  render() {
    const { children, resource, rel, name, uiSchema, forwardedRef, mutateSchema } = this.props;
    const { error, submitting, formData } = this.state;

    const self = resource.hasLink('self') ?
      resource.link('self').href : '<Unknown>';

    if (!name && !resource.hasForm(rel)) {
      return (
        <Callout intent='danger' title='Unable to display form'>
          The resource with URL <Code>{self}</Code> does not contain a Form with
          rel <Code>{rel}</Code>.
        </Callout>
      )
    }

    if (name && !resource.hasFormNamed(rel, name)) {
      return (
        <Callout intent='danger' title='Unable to display form'>
          The resource with URL <Code>{self}</Code> does not contain a Form with
          rel <Code>{rel}</Code> named <Code>{name}</Code>.
        </Callout>
      )
    }

    const form = name ? resource.formNamed(rel, name) : resource.form(rel);

    if (!form.schema) {
      return (
        <Callout intent='danger' title='Unable to display form'>
          The Form with rel <Code>{rel}</Code> and name <Code>{name}</Code> on
          Resource with URL <Code>{self}</Code> does not define a Schema.
        </Callout>
      )
    }

    /**
     * Cast schema to `any` any type definition is incompatible with what rjsf
     * expects, but should be valid at runtime
     */
    const schema: any = mutateSchema ? mutateSchema(form.schema) : form.schema;

    return (
      <>
        <Error {...{ error }} />
        <Form
          {...{ schema, uiSchema, formData, children }}
          disabled={submitting}
          onChange={this._onChange}
          onSubmit={this._onSubmit}
          ref={forwardedRef}
          formContext={{ resource }}
          noHtml5Validate={true}
        />
      </>
    );
  }

  protected _onChange = ({ formData }: IChangeEvent<any>) => {
    this.setState({ formData });
  }

  protected _onSubmit = async ({ formData }: ISubmitEvent<any>) => {
    this.setState({ submitting: true });
    const { resource, rel, name, onSubmit } = this.props;

    try {
      const form = name ? resource.formNamed(rel, name) : resource.form(rel);
      const response = await form.submit(formData)
      this.setState({ error: undefined });

      if (onSubmit) {
        onSubmit(response);
      }
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ submitting: false });
    }
  }
}

export const ResourceForm = React.forwardRef((props: Props, ref) => (
  <_ResourceForm {...props} forwardedRef={ref} />
)) as any as React.ComponentClass<Props>;
