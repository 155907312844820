import { lensPath, over, path } from 'ramda';

const useFirstOneOf = path(['oneOf', 0]);

/**
 * `react-json-schema-form` can't support the way that Prism emits `oneOf`
 * attributes, so remove it and just use the first available definition for a
 * Schema property named `property`.
 */
export const removeOneOf = (property: string) =>
  over(
    lensPath(['properties', property]),
    useFirstOneOf
  )

