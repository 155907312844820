import * as React from 'react';
import { pushStateLocationPlugin, UIRouter, UIView } from '@uirouter/react';

import { states } from './states';

const plugins = [
  pushStateLocationPlugin
];

export const App: React.SFC = () => (
  <UIRouter {...{ states, plugins }}>
    <UIView />
  </UIRouter>
)
