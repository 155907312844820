import * as React from 'react';
import { Button, Callout, Classes, Dialog } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';
import { UIViewResolves } from '@uirouter/react';

import { ResourceForm } from '../components/generic/ResourceForm';

type Props = UIViewResolves & {
  api: Resource;
}

export class Login extends React.Component<Props> {
  protected _formRef = React.createRef<any>();

  render() {
    const { api } = this.props;

    return (
      <Dialog
        isOpen
        title='Welcome to Vex'
        className='login-dialog'
      >
        <div className={Classes.DIALOG_BODY}>
          <Callout
            intent='primary'
          >
            Please use your Axiom Administrator credentials to continue.
          </Callout>
          <ResourceForm
            resource={api}
            rel='token'
            name='create'
            uiSchema={{
              password: {
                'ui:widget': 'password'
              }
            }}
            onSubmit={this._onSubmit}
            ref={this._formRef}
          >
            <React.Fragment />
          </ResourceForm>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              intent='primary'
              icon='chevron-right'
              text='Login'
              onClick={this._onClick}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  protected _onClick = () => {
    this._formRef.current!.submit();
  }

  protected _onSubmit = (token: Resource) => {
    const { router } = this.props.$transition$;
    localStorage.setItem('token', token.properties.access_token);
    router.stateService.go('main', undefined, { reload: true });
  }
}
