import * as React from 'react';
import { Icon, Tooltip } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';

type Props = {
  cohort: Resource;
}

export const RuleCount: React.SFC<Props> = ({ cohort }) => (
  <Tooltip
    content={`${cohort.meta.cohort_membership_rules_count} Membership Rules`}
    className='cohort-rule-count'
  >
    <>
      <Icon icon='link' iconSize={24} />
      <span className='stat'>{cohort.meta.cohort_membership_rules_count}</span>
    </>
  </Tooltip>
)
