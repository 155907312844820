import { UiSchema } from 'react-jsonschema-form';

import { ResourcePicker } from './components/generic/ResourcePicker';

export const cohorts: UiSchema = {
  id: {
    'ui:widget': 'hidden',
  },
  name: {
    'ui:autofocus': true
  },
  description: {
    'ui:widget': 'textarea'
  }
};

export const cohortFlags: UiSchema = {
  id: {
    'ui:widget': 'hidden'
  },
  cohort_id: {
    'ui:widget': 'hidden'
  }
}

export const cohortMembershipRules: UiSchema = {
  id: {
    'ui:widget': 'hidden'
  },
  cohort_id: {
    'ui:widget': 'hidden'
  },
  parameters: {
    extId: {
      'ui:widget': ResourcePicker
    }
  }
}
