import * as React from 'react';
import { Alert } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';

export type Props = {
  resource: Resource;
  message: React.ReactNode;
  onDelete: () => void;
}

type State = {
  open: boolean;
}

export class ResourceDeleteDialog extends React.Component<Props, State> {
  state: State = {
    open: false
  }

  render() {
    const { message } = this.props;
    const { open } = this.state;

    return (
      <Alert
        icon='trash'
        intent='danger'
        isOpen={open}
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        onCancel={this.close}
        onConfirm={this._onConfirm}
      >
        {message}
      </Alert>
    );
  }

  public open = () =>
    this.setState({ open: true });

  public close = () =>
    this.setState({ open: false });

  protected _onConfirm = async () => {
    const { resource, onDelete } = this.props;

    await resource.formNamed('self', 'delete').submit();

    this.close();
    onDelete();
  }
}
