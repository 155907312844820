import * as React from 'react';
import { Button, ButtonGroup, Card, H1, H3, Icon } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';
import { UISref } from '@uirouter/react';
import { Row, Col } from 'react-flexbox-grid';

import { ResourceCollection } from '../components/generic/ResourceCollection';
import { CreateButton } from '../components/cohorts/CreateButton';
import { StatusIcon } from '../components/cohorts/StatusIcon';
import { RuleCount } from '../components/cohorts/RuleCount';
import { FlagCount } from '../components/cohorts/FlagCount';

type Props = {
  api: Resource
}

export const CohortsList: React.SFC<Props> = ({ api }) => (
  <ResourceCollection
    resource={api}
    rel='cohorts'
    always={({ content, refresh }) => (
      <>
        <Row middle='md' className='main-content-heading'>
          <Col md={6}>
            <H1>Cohorts</H1>
          </Col>
          <Col md={6} className='heading-buttons'>
            <ButtonGroup>
              <Button active text='Active' />
              <Button disabled text='Disabled' />
            </ButtonGroup>

            <CreateButton
              resource={api}
              onCreate={refresh}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className='card-list cohorts-list'>
              {content}
            </Card>
          </Col>
        </Row>
      </>
    )}
    empty={() => (
      <div className='card-list-item cohorts-list-item'>
        <H3>No Cohorts Found</H3>
        <p>Create a Cohort using the button above to get started!</p>
      </div>
    )}
  >
    {({ items, refresh }) => items.map(cohort => (
      <div className='card-list-item cohorts-list-item' key={cohort.properties.id}>
        <Row middle='md'>
          <Col md className='status'>
            <StatusIcon {...{ cohort }} />
          </Col>
          <Col md>
            <H3>
              <UISref
                to='.view'
                params={{ id: cohort.properties.id }}
              >
                <a>{cohort.properties.name}</a>
              </UISref>
            </H3>
            <p>{cohort.properties.description || <span>&nbsp;</span>}</p>
          </Col>
          <Col md={2} className='counts'>
            <RuleCount {...{ cohort }} />
            <FlagCount {...{ cohort }} />
          </Col>
        </Row>
      </div>
    ))
    }
  </ResourceCollection>
)
