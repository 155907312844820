import * as React from 'react';
import { Callout, Card, H2 } from '@blueprintjs/core';
import { Resource } from '@optics/hal-client';
import { Row, Col } from 'react-flexbox-grid';

import { ResourceCollection } from '../components/generic/ResourceCollection';
import { CreateButton } from '../components/cohort_membership_rules/CreateButton';
import { Summary } from '../components/cohort_membership_rules/Summary';
import { EditOrDeleteButton } from '../components/cohort_membership_rules/EditOrDeleteButton';

type Props = {
  cohort: Resource;
}

export const CohortRulesList: React.SFC<Props> = ({ cohort }) => (
  <ResourceCollection
    resource={cohort}
    rel='cohort_membership_rules'
    always={({ content, refresh }) => (
      <>
        <Row middle='md' className='main-content-heading'>
          <Col md={6}>
            <H2>Membership Rules</H2>
          </Col>
          <Col md={6} className='heading-buttons'>
            <CreateButton
              resource={cohort}
              onCreate={refresh}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className='card-list cohort-rules-list'>
              {content}
            </Card>
          </Col>
        </Row>
      </>
    )}
    empty={() => (
      <div className='card-list-item cohort-rules-list-item'>
        <Callout icon='zoom-out'>
          This Cohort does not have any Membership Rules defined.
        </Callout>
      </div>
    )}
  >
    {({ items, refresh }) => items.map(membership => (
      <div
        className='card-list-item cohort-rules-list-item'
        key={membership.properties.id}
      >
        <Summary {...{ membership }} />
        <EditOrDeleteButton
          {...{ membership }}
          onChange={refresh}
        />
      </div>
    ))}
  </ResourceCollection>
);
