import * as React from 'react';
import { Resource } from '@optics/hal-client';
import { UiSchema } from 'react-jsonschema-form';

import { callAndToast } from '../../toaster';
import { Button } from '../generic/ResourceFormDialog/Button';
import { cohorts as uiSchema } from '../../uiSchema';

type Props = {
  resource: Resource;
  onUpdate: (resource: Resource) => void;
}

export const EditButton: React.SFC<Props> = ({ resource, onUpdate }) => (
  <Button
    form={{
      resource,
      uiSchema,
      rel: 'self',
      name: 'update',
      onSubmit: callAndToast(onUpdate, {
        message: 'Successfully Updated Cohort',
        intent: 'success'
      })
    }}
    button={{ icon: 'edit', text: 'Edit' }}
    dialog={{ icon: 'edit', title: `Modify Cohort '${resource.properties.name}'` }}
  />
)
