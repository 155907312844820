import * as React from 'react';
import { Button, Callout, Code, Collapse, Pre } from '@blueprintjs/core';
import { HTTPError } from '@optics/hal-client';

type Props = {
  error?: HTTPError;
}

type State = {
  showFullError: boolean;
}

export class Error extends React.Component<Props, State> {
  state: State = {
    showFullError: false,
  }

  render() {
    const { error } = this.props;
    if (!error) {
      return null;
    }

    const { showFullError } = this.state;

    return (
      <Callout intent='danger' title='Form submission failed'>
        {formatMessage(error)}
        <p>HTTP Status: <Code>{error.status} ({error.statusText})</Code></p>
        <Button onClick={this._toggleShowFullError}>
          {showFullError ? 'Hide' : 'Show'} full error
        </Button>
        <Collapse isOpen={showFullError}>
          <Pre style={{ overflowX: 'scroll' }}>
            {JSON.stringify(error)}
          </Pre>
        </Collapse>
      </Callout>
    );
  }

  protected _toggleShowFullError = () => {
    const { showFullError } = this.state;
    this.setState({ showFullError: !showFullError });
  }
}

export const formatMessage = (error: HTTPError) => {
  if (!error.body) {
    return <p>The HTTP Response did not contain a response payload.</p>
  }

  if (typeof error.body === 'string') {
    return <p>Response Payload: <Code>{error.body}</Code></p>
  }

  const { message } = (error.body as any);
  return <p>Message: <Code>{message}</Code></p>;
}
