import * as React from 'react';
import { Resource } from '@optics/hal-client';
import { UIView } from '@uirouter/react';

type Props = {
  api: Resource
}

export const Root: React.SFC<Props> = ({ api }) => (
  <UIView />
);
